import { SentimentScoreTeamHitsResponseModel } from '@capturi/api-sentiment'
import { useTeams } from '@capturi/core'
import { Segment } from '@capturi/filters'
import orderBy from 'lodash/orderBy'
import { useMemo } from 'react'

import { TeamSegments } from '../../shared/types'

export type Datum = {
  conversations: number
  hits: number
  hitRate: number
}

export type TeamData = TeamSegments<Datum>

function useTeamScoreStats(
  segments: Segment<SentimentScoreTeamHitsResponseModel>[],
): TeamData[] {
  const { getTeamByUid } = useTeams()

  return useMemo(() => {
    const allTeamUids = new Set(
      segments.flatMap((s) => (s.data?.teams ?? []).map((x) => x.teamUid)),
    )

    const hitMaps = segments.map<{ [key: string]: Datum }>((s) => {
      return (s.data?.teams ?? []).reduce<{
        [key: string]: Datum
      }>((acc, x) => {
        acc[x.teamUid] = {
          conversations: x.conversations,
          hits: x.conversationsWithScore,
          hitRate: x.conversationsWithScorePercent / 100,
        }
        return acc
      }, {})
    })

    const segmentData = [...allTeamUids].reduce<{ [key: string]: TeamData }>(
      (acc, teamUid) => {
        acc[teamUid] = {
          segments: segments.map<Segment<Datum>>((s, i) => {
            const map = hitMaps[i]
            return {
              color: s.color,
              label: s.label,
              data: map[teamUid] ?? {
                conversations: 0,
                hits: 0,
                hitRate: 0,
              },
            }
          }),
          team: getTeamByUid(teamUid) ?? {
            uid: teamUid,
            name: 'N/A',
            members: [],
            isDeleted: false,
          },
        }
        return acc
      },
      {},
    )

    const data = orderBy(
      Object.values(segmentData),
      [(x) => x.segments[0]?.data?.hitRate, (x) => x.team.name],
      ['desc', 'asc'],
    )

    return data
  }, [segments, getTeamByUid])
}

export default useTeamScoreStats
